.stat-card-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px 10px 0;
  min-width: 200px;
  max-width: 210px;
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  color: #C8C8C8;
  border: 1px solid #615277;
  background: #120d17 !important;
  box-shadow: 0 0 19px 7px rgb(135 62 244 / 21%);


  .reward-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .currency {
      font-size: 18px;
      margin: 2px 0;
    }
  }

  svg {
    margin: 0 !important;
  }

  svg.text-muted {
    color: #C8C8C8 !important;
    font-size: 20px !important;
  }
}

.single-claim-btn {
  background: transparent !important;
  border: 1px solid #C8C8C8 !important;
  border-radius: 6px !important;
  padding: 6px 25px !important;
  font-size: 14px !important;
  width: 100% !important;
  margin: 15px 0 5px 0;
}