.dao-container {
  width: 100%;
  z-index: 100;

  .h2-title {
    margin-top: 30px;
  }

  .empty-container {
    width: 100%;
    text-align: center;
    color: #c8c8c8;
  }

  .page-title {
    color: #c8c8c8;
    padding: 10px 0;
  }

  .proposal-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    margin: 20px 0;
    background: #101010 !important;
    border-radius: 6px;
    opacity: 0.99;

    .proposal-header-title {
      font-size: 32px;
      color: #C8C8C8;
      justify-content: right;
    }

    .proposal-header-power {
      display: flex;
      flex-direction: column;

      .power-title {
        font-size: 18px;
        color: #C8C8C8;
      }

      .power-value {
        font-size: 24px;
        color: #C8C8C8;
        display: flex;
        justify-content: right;
      }
    }

    div {
      color: #fff;
    }
  }
}

h2, p, span {
  color: #c8c8c8;
}

.rewards-container {
  display: flex;
  flex-wrap: wrap;
}

.submit-proposal-btn {
  background: #0a0a0a !important;
  border: 1px solid #C8C8C8 !important;
  border-radius: 6px !important;
}

@media screen and (max-width: 990px) {
  .proposal-bottom-container {
    flex-direction: row !important;
    justify-content: center;
    margin: 20px;
  }
}

@media screen and (max-width: 500px) {
  .proposal-header-container {
    flex-direction: column !important;
  }
  .proposal-bottom-container {
    flex-direction: column !important;
    max-width: 180px;
    width: 100%;
    text-align: center;

    div {
      margin: 10px 0 !important;
    }
  }
}

.submit-proposal-title {
  padding: 20px 0;
  font-size: 28px !important;
}

.submit-proposal-modal .modal-content {
  padding: 20px !important;
}

.submit-proposal-container {
  .proposal-label {
    padding: 10px 0;
    font-size: 16px;
  }

  .proposal-content {
    background: transparent !important;
    padding: 10px;
    color: #C8C8C8 !important;
  }

  .submit-btn {
    margin: 20px 0;
  }
}

.modal-dialog {
  max-width: 750px !important;
}

.no-proposal-msg {
  font-size: 20px;
  color: #C8C8C8;
  text-align: center;
  padding: 20px 0;
}

.spinner-container {
  width: 100%;
  text-align: center;
  position: relative;
  .spinner-and-text-container {
    margin: auto;
    width: 150px !important;
    height: 150px !important;
  }
  .spinner {
    color: #C8C8C8 !important;
    width: 100% !important;
    height: 100% !important;
  }
}