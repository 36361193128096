.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  h1 {
    width: 100%;
    text-align: center;
    color: #c8c8c8;
    margin: 50px 0 20px 0;
  }

  .ape-container {
    display: flex;
    z-index: 1;
  }
}
.apes-preview-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ape-preview-container {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  width: 100%;
  background: #101010;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  margin: 30px 10px;

  .preview-img {
    width: 100%;
  }

  .preview-title {
    color: #c8c8c8;
    text-align: center;
    font-size: 24px;
    padding: 10px;
  }

  .preview-rank {
    color: #c8c8c8;
    text-align: center;
    font-size: 16px;
    padding: 0 0 20px 0;
  }

}

.single-ape-container {
  display: flex;
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  background: #101010;

  .infos-container {
    width: 50%;

    .title {
      font-size: 24px;
      padding: 10px;
    }

    .description {
      font-size: 16px;
      padding: 10px;
    }

    div {
      color: #c8c8c8;
    }

    .attributes {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 10px 0;

      .attribute {
        display: flex;
        flex-direction: column;
        min-width: 100px;
        background: #0a0a0a;
        margin: 10px;
        padding: 15px 20px;
        border-radius: 6px;
        max-width: 125px;
        width: 100%;

        div {
          text-align: center;
          padding: 3px 0;
        }

        div:first-letter {
          text-transform: uppercase;
        }

        .attribute-trait, .attribute-value {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .image-container {
    display: flex;
    justify-content: center;
    width: 50%;

    img {
      max-height: 400px;
      max-width: 400px;
      width: 100%;
      border-radius: 6px;
    }
  }
}

.no-ape-msg {
  color: #c8c8c8;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;

  .tag {
    background: #c8c8c8;
    color: #0a0a0a !important;
    padding: 6px 20px;
    margin: 10px 10px 10px 0;
    border-radius: 20px;
  }
}

/* Modal */
.modal-dialog {
  max-width: 1200px !important;
  padding: 30px;
}
.modal-content {
  background: #101010 !important;

  .modal-header {
    color: #c8c8c8;
    border-bottom: none;

    button {
      color: #c8c8c8;
    }
  }

  .modal-body {
    color: #c8c8c8;
  }
}

@media screen and (max-width: 1000px) {
  .single-ape-container .infos-container .attributes .attribute {
    max-width: 110px;
  }
}

@media screen and (max-width: 1000px) {
  .ape-container {
    justify-content: space-around;
  }
}

@media screen and (max-width: 920px) {
  .infos-container {
    padding: 0 10px;
  }
  .single-ape-container .infos-container .attributes .attribute {
    max-width: 100px;
    margin: 5px;
  }
}

@media screen and (max-width: 850px) {
  .single-ape-container {
    flex-direction: column;
    align-items: center;

    .image-container, .infos-container {
      width: 100%;
    }
  }

  .apes-preview-container {
    justify-content: center;
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

ul.pagination {
  li {
    margin: 0 5px;
    cursor: pointer;

    a {
      font-size: 16px;
      color: #c8c8c8;
    }
  }
}