nav {
  max-width: 1100px;
  width: 100%;
  margin: auto;

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }


  .dropdown-menu {
    background: #101010;
    padding: 10px;

    .dropdown-item {
      color: #747474;
      padding: 2px 0;
    }

    .dropdown-item:hover {
      color: #c8c8c8;
      background: transparent;
    }

  }

  .env {
    font-family: 'Space Mono', monospace !important;
    font-size: 18px;
    color: #c8c8c8;
    padding-left: 10px;
  }

  .container-fluid {
    display: flex;
    margin: 10px 0;
    flex-direction: row;
  }

  .navbar-brand {
    display: flex;
    flex-direction: column;
  }

  .logo {
    width: 300px;
  }

  .nav-menu {
    display: flex;
    flex-direction: row;
  }
  .nav-item {
    display: flex;
    align-items: center;
    margin: 0 15px;
    a {
      font-family: 'Space Mono', monospace !important;
      text-decoration: none;
      font-size: 18px;
      padding: 0 !important;
      color: #c8c8c8 !important;
    }
  }

  .maiar-logo {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  .menu-btn {
    display: flex;
    align-items: center;
    background-size: 400%;
    color: #c8c8c8;
    background: black;
    border: 1px solid #c8c8c8;
    padding: 12px 30px;
    font-size: 16px;
  }
}

.logout {
  font-size: 16px;
  margin: 0 0 0 10px;
  padding: 5px 10px;
}

@media screen and (max-width: 1000px) {
  .container-fluid, .nav-menu {
    flex-direction: column !important;
    margin: auto;

    .nav-item {
      margin: auto;
      padding: 3px 0;
    }
  }
}

.dropdown-item.active, .dropdown-item:active {
  color: #747474 !important;
  background-color: transparent !important;
}

@media screen and (max-width: 1200px) {
  nav .logo {
    width: 200px !important;
  }
}

.navbar-collapse {
  justify-content: end;
}