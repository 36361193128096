.container {
  justify-content: center;
}

.home-container {
  width: 100%;
  margin: auto;
  max-width: 1000px;
  border-radius: 6px;
  z-index: 0;
  display: flex;
  flex-direction: row;
  padding: 40px 0;
  justify-content: space-around;

  .left {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;

    img {
      max-width: 450px;
      width: 100%;
      border-radius: 6px;
    }
  }

  .right {
    width: 50%;
    max-width: 325px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .card-container-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    /** Container for buying */
    .card-buy-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      .buy-ape-btn {
        background: transparent;
        border: 1px solid #c8c8c8;
        padding: 6px 16px;
        font-size: 20px;
        border-radius: 3px;
      }

      .buy-ape-btn.active {
        border: 1px solid #c8c8c8;
        span {
          color: #c8c8c8;
        }
      }
      .buy-ape-btn.inactive {
        border: 1px solid #747474;
        span {
          color: #747474;
        }
      }
    }

    .reward-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      .balance-container {
        display: flex;
        flex-direction: column;
        text-align: center;

        span {
          font-size: 14px;
        }
      }

      .balance-container.active {
        span {
          color: #c8c8c8;
        }
      }

      .balance-container.inactive {
        span {
          color: #747474;
        }
      }

      .claim-btn {
        background: transparent;
        padding: 6px 16px;
        border-radius: 3px;
      }

      .claim-btn.active {
        border: 1px solid #c8c8c8;
        color: #c8c8c8;
      }

      .claim-btn.inactive {
        border: 1px solid #747474;
        color: #747474;
      }
    }

    .countdown-container {
      .launch-text, .launch-countdown {
        color: #c8c8c8;
        text-align: center;
      }

      .launch-text {
        font-family: "Roboto", monospace;
        font-size: 18px;
      }

      .launch-countdown {
        font-family: "Roboto", monospace;
        font-size: 24px;
      }
    }

    .card-container {
      background: #101010;
      width: 100%;
      padding: 15px;
      border-radius: 6px;

      .mint-info {
        display: flex;
        flex-direction: column;
      }

      .mint-info-text {
        color: #c8c8c8;
        text-align: center;
      }
    }
  }
}

.buy-container.active {
  span {
    color: #c8c8c8;
    font-size: 20px;
  }
}

.buy-container.inactive {
  span {
    color: #747474;
    font-size: 20px;
  }
}

.buy-container {

  h2 {
    font-size: 50px !important;
    color: #c8c8c8;
  }

  .direction {
    cursor: pointer;
    padding: 0 20px;
  }
}

.buy {
  padding: 15px 30px;
  background: #fff;
  color: #000;
  border: none;
  border-radius: 20px;
}

@media screen and (max-width: 992px) {
  .home-container .left {
    padding: 0 !important;
  }
}

@media screen and (max-width: 900px) {
  .home-container {
    flex-direction: column;
    justify-content: center;
  }

  .left, .right {
    margin: auto;
    width: 100% !important;
  }

  .right {
    justify-content: space-around !important;
    height: 350px;
  }
}

@media screen and (max-width: 550px) {
  .left {
    width: 90% !important;
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.fs-20 {
  font-size: 20px;
}

.fs-30 {
  font-size: 30px;
}

.pd-10 {
  padding: 10px;
}

.connect-btn {
  background: transparent;
  border-radius: 3px;
  border: 1px solid #fff;
  color: #fff;
  padding: 10px 20px;
}

/* Modals */
.modal-dialog {
  .modal-content {
    .card {
      background: transparent;

      .card-body {
        span, p, h4, .form-group {
          color: #c8c8c8;
        }

        p.h4 {
          font-family: "Space Mono", monospace;
        }

        span, p, h4, .form-group {
          font-family: "Roboto Mono", monospace;
        }

        button {
          margin: 10px 0 !important;
          padding: 10px 20px;
          font-size: 16px;
          font-family: "Roboto Mono",monospace;
          color: #c8c8c8;
          border: 1px solid #c8c8c8;
          border-radius: 6px;
          background: transparent;
        }
      }
    }
  }
}


/* Modal For Connect Wallet */
.m-auto {
  max-width: 600px;
  width: 100%;
}

.m-auto .card {
  background: transparent;
}

.m-auto .card-body {
  background: #101010;
  overflow: hidden;
  border: 1px solid #c8c8c8;
  border-radius: 6px;

  h4, p {
    color: #c8c8c8;
    font-family: "Space Mono", monospace;
  }

  div {
    // Hide Elrond Web Wallet
    :nth-child(4) {
      //display: none;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    padding: 5px 20px;

    a, button {
      margin: 10px 0 !important;
      padding: 10px 0;
      font-size: 16px;
      font-family: "Roboto Mono",monospace;
      color: #c8c8c8;
      border: 1px solid #c8c8c8;
      border-radius: 6px;
      background: transparent;
    }

    a:hover, button:hover {
      color: black;
      border: 1px solid #c8c8c8;
      background: #c8c8c8;
    }
  }
}

.secondaryLogo {
  display: flex;
  justify-content: center;


  & img {
    cursor: pointer;
    width: 50px;
  }
}

.emoon {
  padding: 0px 10px;
}
