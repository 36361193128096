/* Modal */

.stake-modal {
  .modal-dialog {
    max-width: 1200px !important;
    width: 100% !important;
  }

  .ape-view {
    width: 100%;
    display: flex;
    justify-content: space-around;

    .section-name {
      text-align: center;
      padding: 10px 0;
      font-size: 20px;
      text-shadow: rgba(224,224,224,0.9) 0 0 17px;
    }

    .apes-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    .wallet-apes, .staked-apes {
      width: 45%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .ape-nonce {
        text-align: center;
        color: #C8C8C8;
        padding: 10px;
        position: absolute;
        font-weight: 900;
        font-size: 16px;
      }
    }
  }
}

.claim-modal, .stake-modal {
  padding: 30px;
  margin: auto;
  width: 100%;

  .modal-dialog {
    width: 100%;
  }

  .modal-content {
    background: #101010 !important;

    .modal-header {
      color: #c8c8c8;
      border-bottom: none;

      button {
        color: #c8c8c8;
      }
    }

    .modal-body {
      color: #c8c8c8;
    }

    .modal-footer {
      border-top: none;
      padding: 0 20px;
      justify-content: flex-start;
    }
  }
}

.rewards-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .reward-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 10px;
  }
}

.claim-btn-modal {
  background: #101010 !important;
  border: 1px solid #C8C8C8 !important;
  border-radius: 4px;
}

.btn-action {
  display: flex;
  justify-content: end;
  padding: 10px 20px;
}

.wallet-ape {
  position: relative;
  width: 150px !important;
  height: 158px;
  border-radius: 6px;
  overflow: hidden;
}

.selected-wallet-ape {
  border: 1px solid #C8C8C8;
  -webkit-box-shadow:0 0 29px 10px rgba(200,200,200,0.16);
  -moz-box-shadow: 0 0 29px 10px rgba(200,200,200,0.16);
  box-shadow: 0 0 29px 10px rgba(200,200,200,0.16);
}

.wallet-ape-img {
  position: absolute;
  width: 100%;
}

.preview-ape-container {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  cursor: pointer;
}

.form-check-container {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;

  input {
    width: 1.1em;
    height: 1.1em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    //appearance: none;
    //-webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }

  input:checked {
    background-color: gray;
  }
}

.form-check {
  top: 120px;
  right: 5px;
}

.unlock-time {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  background: black !important;
  top: 108px;
  text-align: center;
  justify-content: center;
  opacity: 0.8;

  .unlock-time-container {
    display: flex;
    flex-direction: column;
  }
}

.action-btn-modal-container {
  display: flex;
  justify-content: center;
  padding: 10px 0;

  .modal-action-btn {
    background: black !important;
    font-size: 16px !important;
    border: 1px solid #C8C8C8 !important;
    border-radius: 3px !important;
    width: 150px;
  }
}

@media screen and (max-width: 1000px) {
  .ape-view {
    flex-direction: column;
  }

  .wallet-apes, .staked-apes {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .vertical-bar {
    display: none;
  }
}

.vertical-bar {
  width: 1px;
  background: #C8C8C8;
}

.reward-currency {
  font-size: 16px;
}