footer {
  z-index: 10;
  padding: 30px;

  .social-icons-container {

    i {
      font-size: 20px;
    }

    .social-icon {
      padding: 0 15px;
      color: #fff;
    }

    .discord-icon, .twitter-icon {
      img {
        width: 20px;
        height: 16px;
      }
    }

    .telegram-icon {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}