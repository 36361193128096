.staking-container {
  width: 100%;

  .staking-pools-container {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0;
  }
}

.staking-reward-container {
  z-index: 10;
  padding: 20px 35px;
  width: 100% !important;

  .title {
    padding: 10px 0;
    color: #c8c8c8;
  }

  .staking-reward-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

.no-rewards {
  color: #C8C8C8
}

@media screen and (max-width: 992px) {
  .staking-pools-container {
    flex-direction: column;
    align-items: center;
  }
}

.claim-btn {
  background: transparent !important;
  border: 1px solid #C8C8C8 !important;
  border-radius: 6px !important;
  padding: 6px 25px !important;
  font-size: 16px;
}