.staking-pool-container {
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 330px;
  max-width: 330px;
  width: 100%;
  padding: 30px 30px;
  border: 1px solid #615277;
  border-radius: 20px;
  color: #fff;
  background: #120d17 !important;
  -webkit-box-shadow:0 0 53px 31px rgba(135,62,244,0.21);
  -moz-box-shadow: 0 0 53px 31px rgba(135,62,244,0.21);
  box-shadow: 0 0 53px 31px rgba(135,62,244,0.21);
  margin: 20px;

  .pool-title {
    font-size: 32px;
    text-transform: uppercase;
    text-align: center;
    text-shadow: rgb(224 224 224 / 90%) 0 0 17px;
  }

  .pool-status-container {
    display: flex;
    justify-content: end;
    width: 100%;
    .dot {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      display: inline-block;
    }

    .pool-enabled {
      background-color: #31FB4A;
    }

    .pool-disabled {
      background-color: #FA0C08;
    }

    .pool-frozen {
      background-color: #00BFFF;
    }
  }

  .pool-description {
    padding: 20px 0 25px 0;
    font-size: 16px;
    color: #C8C8C8;
    width: 100%;
    height: 170px;
    overflow: hidden;
    text-align: justify;
  }

  .pool-lock-time {
    display: flex;
    width: 100%;
    justify-content: end;
    font-size: 20px;
    font-weight: 600;
    color: #C8C8C8;
  }

  .pool-stat {
    display: flex;
    width: 100%;
    justify-content: end;
    font-size: 16px;
    font-weight: 600;
    color: #C8C8C8;
  }

  .action-btn-container {
    margin: 15px 0;
  }

  .pool-btn {
    background: transparent;
    text-transform: uppercase;
    width: 100%;
    padding: 15px 0;
    margin: 10px 0 0 0;
    border-radius: 6px;

    span {
      font-size: 20px;
      text-shadow:
         0 0 0 #fff,
         0 0 0 #fff,
         0 0 2px #CDB4DB,
         0 0 0 #CDB4DB,
         0 0 0 #CDB4DB,
         0 0 0 #CDB4DB,
         0 0 0 #CDB4DB,
         0 0 0 #CDB4DB;
    }
  }

  .stake-btn {
    border: 1px solid #9580b4;
    background: rgb(20,13,29);
    background: linear-gradient(45deg, rgba(20,13,29,1) 0%, rgba(65,32,116,1) 100%);
  }

  .claim-btn {
    background: rgb(20,16,30);
    background: linear-gradient(45deg, rgba(20,16,30,1) 0%, rgba(37,35,75,1) 100%);
    border: 1px solid #89c1ff;
  }

  .pool-btn:hover {
    border: 1px solid #C8C8C8 !important;
  }

}

/* Custom pool background */

.prime-background {
    background: url('../../assets/img/primepool.png') !important;
}

.jacket-background {
    background: url('../../assets/img/jacketpool.png') !important;
}

.kimono-background {
  background: url('../../assets/img/kimonopool.png') !important;
}

.prime-background, .jacket-background, .kimono-background {
    background-size: cover !important;
}

