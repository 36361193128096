.proposal-container {
  display: flex;
  background: #101010 !important;
  opacity: 0.99;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 30px;
  margin: 20px 0;
  border-radius: 6px;

  .core-proposal-container {
    display: flex;
  }

  .proposal-id-container {
    font-weight: 700;
    font-size: 40px;
    color: #C8C8C8;
  }

  .proposal-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 30px;

    .proposal-description {
      font-size: 16px;
      padding: 5px 0;
      color: #C8C8C8;
    }

    .proposal-owner {
      background: #0a0a0a;
      color: #747474;
      width: fit-content;
      padding: 5px 10px;
      border-radius: 3px;
      margin-top: 15px;
    }
  }

  .proposal-stats {
    display: flex;
    flex-direction: column;
    min-width: 135px;
    padding: 10px 0;

    .action-btn {
      padding: 5px 35px;
      background: transparent;
      font-family: "Roboto Mono", monospace;
      margin: 0 0 15px 0;
      text-transform: uppercase;
    }

    .btn-primary {

    }

    .approve-btn {
      color: #31FB4A;
      border: 1px solid #31FB4A;
    }

    .reject-btn {
      color: #FA0C08;
      border: 1px solid #FA0C08;
    }

    .remaining-time {
      text-align: center;
      color: #C8C8C8;
    }
  }
}

.no-upvote {
  color: #31FB4A;
}

.no-downvote {
  color: #FA0C08;
}

.approval-stats {
  margin-bottom: 5px;
  .progress {
    height: 5px !important;
    background: transparent;
   .progress-bar {
     background: #31FB4A !important;
   }
  }
}

.reject-stats {
  margin-bottom: 5px;
  .progress {
    height: 5px !important;
    background: transparent;
    .progress-bar {
     background: #FA0C08 !important;
   }
  }
}

.vote-text {
  display: flex;
  align-items: center;
  svg {
    margin: 0 3px;
  }
}

.quorum {
  font-size: 12px;
  text-align: center;
  padding: 3px 0;
  color: #C8C8C8;
}

.vote-detail {
  font-size: 14px;
  color: #C8C8C8;
  margin: 0 10px;
}

@media screen and (max-width: 992px) {
  .proposal-container {
    display: flex;
    flex-direction: column;
  }
}